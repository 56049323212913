<template>
  <section>
    <AddEditRentStationArea @refresh="$store.dispatch('fsTable/fetchData')" />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2 md:justify-between">
          <title-plus
            :title="
              pageTitle || $t('components.rentStationAreaManagement.headline')
            "
            @plus="add"
            :hidePlus="hidePlusButton"
          />
        </div>

        <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            :title="$t('components.rentStationAreaManagement.summary.total')"
            :value="indexMetaData.summary.total"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t(
                'components.rentStationAreaManagement.summary.totalAssignedVehicles'
              )
            "
            :value="indexMetaData.summary.total_assigned_vehicles"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t('components.rentStationAreaManagement.summary.totalRentals')
            "
            :value="indexMetaData.summary.total_rentals"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t(
                'components.rentStationAreaManagement.summary.totalOngoingRentals'
              )
            "
            :value="indexMetaData.summary.total_ongoing_rentals"
            variant="gray"
          />
        </div>
      </div>
      <template>
        <div
          v-if="$acl.canNotView('Rent Areas')"
          class="py-5 font-bold text-center text-gray-600"
        >
          {{ $t('components.acl.doNotHavePermission') }}
        </div>
        <FSTable
          v-else
          :fst-id="fstId"
          :qso="qso"
          :headers="getTableHeaders"
          :endpoint="endpoint"
          :isMultipleViewEnabled="true"
          @meta="(e) => (indexMetaData = e)"
          @view="(v) => (layoutView = v)"
        >
          <template #default="{ data }">
            <template v-if="layoutView === 'list'">
              <template v-for="(item, itemIndex) in data">
                <template v-if="getTableMode === 'full'">
                  <FSTableRow :key="itemIndex" textFallbackAlways>
                    <FSTableRowItem>
                      <router-link
                        class="font-bold text-blue-600 capitalize"
                        :to="{
                          name: 'ViewRenStationArea',
                          params: { id: item.id },
                        }"
                      >
                        {{ item.name }}
                      </router-link>
                    </FSTableRowItem>
                    <FSTableRowItem>
                      <router-link
                        class="font-bold text-blue-600 capitalize"
                        :to="{
                          name: 'ViewServiceArea',
                          params: {
                            id: item.geofence.id ? item.geofence.id : '',
                          },
                        }"
                      >
                        {{ item.geofence.name || '--' }}
                      </router-link>
                    </FSTableRowItem>
                    <FSTableRowItem :text="item.address" />
                    <FSTableRowItem>
                      <x-status
                        :variant="item.is_active ? 'green' : 'gray'"
                        :text="item.is_active ? 'Active' : 'Inactive'"
                        profile="rent-station"
                      />
                    </FSTableRowItem>
                    <FSTableRowItem class="flex items-center">
                      <OtoEditIcon @click="edit(item)" />
                      <MoreActionsDropdown
                        :key="`more-actions-${itemIndex}`"
                        :type="`rentStationArea`"
                        :data="item"
                        @exportKML="handleExportKML(item)"
                        @change="handleAction('change', itemIndex, $event)"
                        @delete="handleAction('delete', itemIndex, $event)"
                      />
                    </FSTableRowItem>
                  </FSTableRow>
                </template>
                <template v-if="getTableMode === 'responsive'">
                  <FSTableRow
                    :key="`fs-table-row-${itemIndex}`"
                    textFallbackAlways
                  >
                    <FSTableRowItem>
                      <div
                        class="col-span-1 focus:text-gray-400"
                        @click="toggle(itemIndex)"
                      >
                        <i
                          class="fas fa-minus-circle"
                          style="color:#d90a20;"
                          v-if="opened.includes(itemIndex)"
                        ></i>
                        <i class="fas fa-plus-circle" v-else></i>
                      </div>
                    </FSTableRowItem>
                    <FSTableRowItem>
                      <router-link
                        class="font-bold text-blue-600 capitalize"
                        :to="{
                          name: 'ViewRenStationArea',
                          params: { id: item.id },
                        }"
                      >
                        {{ item.name }}
                      </router-link>
                    </FSTableRowItem>
                    <FSTableRowItem>
                      <router-link
                        class="font-bold text-blue-600 capitalize"
                        :to="{
                          name: 'ViewServiceArea',
                          params: {
                            id: item.geofence.id ? item.geofence.id : '',
                          },
                        }"
                      >
                        {{ item.geofence.name || '--' }}
                      </router-link>
                    </FSTableRowItem>
                    <FSTableRowItem>
                      <x-status
                        :variant="item.is_active ? 'green' : 'gray'"
                        :text="item.is_active ? 'Active' : 'Inactive'"
                        profile="rent-station"
                      />
                    </FSTableRowItem>
                  </FSTableRow>
                  <FSTableRow
                    v-if="opened.includes(itemIndex)"
                    :key="itemIndex"
                  >
                    <td colspan="10">
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">
                          {{
                            $t(
                              'components.parkingAreaManagement.table.columns.address'
                            )
                          }}
                        </div>
                        <div class="col-span-5 right-text">
                          {{ item.address || '--' }}
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-10 items-center w-full expand-item"
                      >
                        <div class="col-span-5 left-text">
                          {{
                            $t(
                              'components.parkingAreaManagement.table.columns.actions'
                            )
                          }}
                        </div>
                        <div class="col-span-5 right-text">
                          <div class="flex items-center">
                            <OtoEditIcon @click="edit(item)" />
                            <MoreActionsDropdown
                              :key="`more-actions-${itemIndex}`"
                              :type="`rentStationArea`"
                              :data="item"
                              @exportKML="handleExportKML(item)"
                              @change="
                                handleAction('change', itemIndex, $event)
                              "
                              @delete="
                                handleAction('delete', itemIndex, $event)
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                  </FSTableRow>
                </template>
              </template>
            </template>
            <template v-if="layoutView === 'grid'">
              <FSTableRow
                v-for="(item, itemIndex) in data"
                :key="itemIndex"
                text-fallback-always
              >
                <FSTableGridItem>
                  <div
                    class="bg-gray-50 rounded-lg flex justify-between items-center p-1"
                  >
                    <div>
                      <router-link
                        class="font-bold text-blue-600 capitalize"
                        :to="{
                          name: 'ViewRenStationArea',
                          params: { id: item.id },
                        }"
                      >
                        {{ item.name }}
                      </router-link>
                    </div>
                    <div class="flex items-center">
                      <OtoEditIcon @click="edit(item)" />
                      <MoreActionsDropdown
                        :key="`more-actions-${itemIndex}`"
                        :type="`rentStationArea`"
                        :data="item"
                        @exportKML="handleExportKML(item)"
                        @change="handleAction('change', itemIndex, $event)"
                        @delete="handleAction('delete', itemIndex, $event)"
                      />
                    </div>
                  </div>
                  <div class="h-52">
                    <MapView :data="item" />
                  </div>
                </FSTableGridItem>
              </FSTableRow>
            </template>
          </template>
        </FSTable>
      </template>
    </content-section>
  </section>
</template>
<script>
import MapView from '@/components/service-area/MapView'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableGridItem,
} from '@/components/fs-table'
import { RentStationAreaConfig } from '@/config/RentStationAreaConfig'
import { EventBus } from '@/utils'
import {
  parseToOverlay,
  createKML,
  downloadKMLFile,
} from '@/composites/geofence/shared/geofence'
export default {
  name: 'RentStationAreas',
  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    XStatus: () => import('@/components/badge/XStatus'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    AddEditRentStationArea: () =>
      import('@/views/geofence/rent-station-area/AddEditRentStationArea.vue'),
    OtoEditIcon: () => import('@/components/ui/OtoEditIcon'),
    MoreActionsDropdown: () =>
      import('@/composites/geofence/shared/MoreActionsDropdown'),

    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableGridItem,
    MapView,
  },
  props: {
    fstId: {
      type: String,
      default: 'rent-station-index',
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '?' }),
    },
    pageTitle: {
      type: String,
      default: '',
    },
    endpoint: {
      type: String,
      default: RentStationAreaConfig.api.index,
    },
    hidePlusButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      layoutView: 'list',
      indexMetaData: {
        summary: {
          total: 0,
          total_assigned_vehicles: 0,
          total_rentals: 0,
          total_ongoing_rentals: 0,
        },
      },
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.name'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.serviceAreaName'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.address'
          )}`,
          width: '35%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.status'
          )}`,
          width: '35%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.actions'
          )}`,
          width: '20%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.name'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.serviceAreaName'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.parkingAreaManagement.table.columns.status'
          )}`,
          width: '15%',
          sort: null,
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    parseToOverlay,
    createKML,
    downloadKMLFile,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    add: function() {
      console.log('rent station = ')
      EventBus.$emit(RentStationAreaConfig.events.editingData, {})
      dispatchEvent(new Event(RentStationAreaConfig.events.sorToggle))
    },
    edit: function(item) {
      console.log(item)
      this.$http
        .get(RentStationAreaConfig.api.details(item.id))
        .then((res) => {
          console.log('rent station  = ', res.data)
          EventBus.$emit(RentStationAreaConfig.events.editingData, res.data)
          dispatchEvent(new Event(RentStationAreaConfig.events.sorToggle))
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleAction(type, index, data) {
      if (type === 'change') {
        const activeInactive = data.is_active === true ? 'active' : 'inactive'
        const activatedDeactivated =
          data.is_active === true ? 'activated' : 'deactivated'

        this.$notify({
          type: `success`,
          group: `generic`,
          title: `Rent station area ${activatedDeactivated}`,
          text: `${data.name} rent station area is now ${activeInactive}`,
        })
        this.$store.dispatch('fsTable/fetchData')

        return
      }

      if (type === 'delete') {
        this.$store.dispatch('fsTable/fetchData')

        this.$notify({
          type: `success`,
          group: `generic`,
          title: `Success`,
          text: `Rent Station area has been deleted`,
        })

        return
      }
    },
    handleExportKML(data) {
      const coordinates = this.parseToOverlay(data.coords)

      const kmlString = this.createKML(coordinates)

      this.downloadKMLFile(data.name, kmlString)
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
